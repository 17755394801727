import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import FAQAccordion from '../components/FAQAccordion';
import CircularProgress from '@mui/material/CircularProgress';

const FAQ = () => {
    const [faqData, setFaqData] = useState([]);

    useEffect(() => {
        // Fetch adatokat az adott végpontból
        fetch('https://app.kvery.io/query/api/120da4fb5c6/1690805127wEADsmLhQLNik5BsE5O7lk')
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 1) {
                    setFaqData(data.response);
                }
            })
            .catch((error) => {
                console.error('Hiba történt a fetch közben:', error);
            });
    }, []);

    return (
        <Container sx={{display: 'flex', alignItems: 'center', height: '80vh', justifyContent: 'center'}}>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                bgcolor: '#fff',
                width: '680px',
                borderRadius: '8px',
                justifyContent: 'center',
                alignContent: 'center',
                padding: '25px',
                minHeight: '310px'
            }}>
                <p className="boxTitle">Gyakori kérdések:</p>
                {faqData.length > 0 ? <FAQAccordion faqData={faqData}/> : <CircularProgress/>}
                <div style={{display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between'}}>
                    <Button style={{marginTop: '25px'}} variant="outlined" onClick={() => {
                        window.location.href = '/';
                    }}>
                        Vissza
                    </Button>
                </div>
            </Box>
        </Container>
    );
};

export default FAQ;
