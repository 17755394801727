import React from 'react';
import Container from '@mui/material/Container';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import OptionContainer from '../components/Home/OptionContainer';
import CardOption from '../components/Home/CardOption';
import PaidIcon from '@mui/icons-material/Paid';
import { useSelector } from 'react-redux';

const Home = () => {
    const user = useSelector((state) => state.login.user); // Access user data from Redux state

    return (
        <>
            <Container sx={{ display: 'flex', alignItems: 'center', height: '80vh', justifyContent: 'center' }}>
                <OptionContainer >
                    <p className="boxTitle">Kérem válasszon az alábbi lehetőségek közül:</p>
                    <CardOption
                        icon={<LocalOfferIcon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '25%', fontSize: '35px', color: '#3D5A80' }} />}
                        title="Kártya ajánlása"
                        onClick={() => {
                            window.location.href = '/proposal';
                        }}
                    />
                    {/* Render "Értékesítés" card only if the user is a reseller */}
                    {user && user.isReseller && (
                        <CardOption
                            icon={<PaidIcon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '25%', fontSize: '35px', color: '#3D5A80' }} />}
                            title="Értékesítés"
                            onClick={() => {
                                window.location.href = '/sales';
                            }}
                        />
                    )}
                    <CardOption
                        icon={<CreditScoreIcon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '25%', fontSize: '35px', color: '#3D5A80' }} />}
                        title="Kártyaszám ellenőrzés"
                        onClick={() => {
                            window.location.href = '/check';
                        }}
                    />
                    <CardOption
                        icon={<QuestionAnswerIcon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '25%', fontSize: '35px', color: '#3D5A80' }} />}
                        title="Gyakori kérdések"
                        onClick={() => {
                            window.location.href = '/faq';
                        }}
                    />
                </OptionContainer>

            </Container>

        </>
    );
};

export default Home;
