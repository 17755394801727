import React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InfoIcon from '@mui/icons-material/Info';

const EmailSendSuccessFully = ({ label, value }) => {
    return (
        <>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    bgcolor: '#fff',
                    height: '350px',
                    width: '460px',
                    borderRadius: '8px',
                    justifyContent: 'center',
                    alignContent: 'center',
                    padding:'25px'
                }}>
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center', width: '100%' }}>
                            <InfoIcon style={{ fontAlign:'center', fontSize: '50px', color: '#088651' }} />
                            </div>
                            <p className="boxTitle">Sikeresen elküldve!</p>
                            <p>Az ügyfél pár percen belül megkapja a tájékoztató e-mailt.</p>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center', width: '100%' }}>
                                <Button style={{ marginTop: '25px', width: '80%', transformText:'uppercase',justifyContent: 'center' }} variant="outlined" onClick={() => { window.location.href = '/'; }}>Vissza a kezdőoldalra</Button>
                            </div>
                        </>
                </Box>
        </>
    );
};

export default EmailSendSuccessFully;