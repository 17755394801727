import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQItem = ({ summary, details }) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography style={{fontWeight:'bold'}}>{summary}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{details}</Typography>
            </AccordionDetails>
        </Accordion>
    );
};


const FAQAccordion = ({ faqData }) => {
    return (
        <>
            {faqData.map((item) => (
                <FAQItem key={item.id} summary={item.faq_title} details={item.faq_description} />
            ))}
        </>
    );
};

export default FAQAccordion;
