import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Check from './pages/Check';
import FAQ from './pages/FAQ';
import CardProposal from './pages/CardProposal';
import Sales from './pages/Sales';
import { selectIsLoggedIn } from './store/loginSlice';

function App() {
    const isLoggedIn = useSelector(selectIsLoggedIn);

    return (
        <>
            <Header />
            <Router>
                <Routes>
                    {!isLoggedIn ? (
                        <>
                            {/* If the user is not logged in, only the /login and /register pages are accessible */}
                            <Route path="/" element={<Login />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="*" element={<Navigate to="/login" replace />} />
                        </>
                    ) : (
                        <>
                            {/* If the user is logged in, the following pages are accessible */}
                            <Route path="/" element={<Home />} />
                            <Route path="/check" element={<Check />} />
                            <Route path="/faq" element={<FAQ />} />
                            <Route path="/proposal" element={<CardProposal />} />
                            <Route path="/sales" element={<Sales />} />
                            {/* Redirect /login to / if the user is already logged in */}
                            <Route path="/login" element={<Navigate to="/" replace />} />
                            {/* If a non-existent page is navigated, redirect to / */}
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </>
                    )}
                </Routes>
            </Router>
            <Footer />
        </>
    );
}

export default App;
