import React from 'react';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const StepOneContent = () => {
    return (
        <Box style={{display: 'flex', flexDirection: 'column', marginBottom: '20px'}}>
            <TextField id="standard-basic" label="E-mail cím" variant="standard" />
            <TextField id="standard-basic" label="Telefonszám" variant="standard" />
        </Box>
    );
};

export default StepOneContent;
