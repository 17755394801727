import React from 'react';

const CheckTable = ({ label, value }) => {
    return (
        <div className='checkTable'>
            <div style={{ width: '50%', fontWeight: 'bold' }}>
                {label}:
            </div>
            <div style={{ width: '50%' }}>
                {value}
            </div>
        </div>
    );
};

export default CheckTable;