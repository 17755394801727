import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import CheckForm from '../components/Check/CheckForm';
import CheckResults from '../components/Check/CheckResults';

const Check = () => {
    const [cardId, setCardId] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [cardData, setCardData] = useState(null);
    const [isUserNotFound, setIsUserNotFound] = useState(false); // New state for Snackbar
    const [isCardNotFound, setIsCardNotFound] = useState(false); // New state for Snackbar

    const handleCardIdChange = (event) => {
        const value = event.target.value;
        if (!/^\d*$/.test(value)) {
            setIsUserNotFound(true);
        } else {
            setIsUserNotFound(false);
            setCardId(value);
        }
    };

    const handleCloseSnackbar = () => {
        setIsUserNotFound(false);
    };

    const handleCloseCardNotFoundSnackbar = () => {
        setIsCardNotFound(false);
    };

    const handleCheckClick = () => {
        fetch(`https://app.kvery.io/query/api/120da4fb5c6/169074816303mRj9f6MODHUhYXHez9m0?cardId=${cardId}`)
            .then(response => response.json())
            .then(data => {
                if (data?.response?.length > 0) {
                    setCardData(data.response[0]);
                    setShowResults(true);
                } else if (data?.status === 0 && data.response === '') {
                    setIsCardNotFound(true);
                    setShowResults(false);
                } else {
                    setShowResults(false);
                }
            })
            .catch(error => {
                console.error(error);
                setShowResults(false);
            });
    };

    const user = useSelector((state) => state.login.user);
    useEffect(() => {
        if (user && user.medioId) {
            setInstitutionId(user.medioId);
        }
    }, [user]);

    const [institutionData, setInstitutionData] = useState();
    const [institutionId, setInstitutionId] = useState('');

    useEffect(() => {
        if (user && user.medioId) {
            setInstitutionId(user.medioId);
        }
    }, [user]);

    useEffect(() => {
        if (institutionId) {
            fetch(`https://app.kvery.io/query/api/120da4fb5c6/1690809051vHdP39z6XWIQGY8xZTqVxa?institutionId=${institutionId}`)
                .then(response => response.json())
                .then(data => {
                    if (data?.status === 1 && data?.response?.length > 0) {
                        setInstitutionData(data.response[0]);
                    } else {
                        setInstitutionData(null);
                    }
                })
                .catch(error => {
                    console.error('Hiba történt a fetch közben:', error);
                    setInstitutionData(null);
                });
        } else {
            console.log(institutionId)
        }
    }, [institutionId]);

    return (
        <>
            <Container sx={{ display: 'flex', alignItems: 'center', height: '80vh', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', bgcolor: '#fff', width: '550px', minHeight: '350px', borderRadius: '8px', justifyContent: 'center', alignContent: 'center', padding: '25px' }}>
                    {showResults ? (
                        <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                            <CheckResults cardData={cardData} institutionData={institutionData} />
                        </div>
                    ) : (
                        <CheckForm cardId={cardId} handleCardIdChange={handleCardIdChange} handleCheckClick={handleCheckClick} />
                    )}
                </Box>
            </Container>
            <Snackbar open={isUserNotFound} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
                    Csak numerikus karakter használható!
                </MuiAlert>
            </Snackbar>
            <Snackbar open={isCardNotFound} autoHideDuration={5000} onClose={handleCloseCardNotFoundSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseCardNotFoundSnackbar} severity="error">
                    Nem található kártyaszám!
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default Check;
