import React, { useState } from 'react';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EmailSendSuccessFully from "../components/CardProposal/EmailSendSuccessFully";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const CardProposal = () => {
    const [showOtherComponent, setShowOtherComponent] = useState(false);
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState(false); // Az error állapot az értesítés megjelenítéséhez

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleNextClick = () => {
        if (isValidEmail(email)) {
            setShowOtherComponent(true);
        } else {
            setIsError(true); // Hibás e-mail esetén az isError értéket true-ra állítjuk
        }
    };

    const handleCloseSnackbar = () => {
        setIsError(false); // Értesítés bezárása
    };

    const isValidEmail = (email) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
    };

    return (
        <>
            <Container sx={{ display: 'flex', alignItems: 'center', height: '80vh', justifyContent: 'center' }}>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    bgcolor: '#fff',
                    minHeight: '300px',
                    width: '460px',
                    borderRadius: '8px',
                    justifyContent: 'center',
                    alignContent: 'center'
                }}>
                    {!showOtherComponent && (
                        <>
                            <p className="boxTitle">Adja meg az e-mail címet:</p>
                            <TextField id="standard-basic" label="Ajánlott e-mail címe" variant="standard" value={email} onChange={handleEmailChange} />
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between', width: '80%' }}>
                                <Button style={{ marginTop: '25px', width: '45%' }} variant="outlined" onClick={() => { window.location.href = '/'; }}>Vissza</Button>
                                <Button style={{ marginTop: '25px', width: '45%' }} variant="contained" onClick={handleNextClick}>Tovább</Button>
                            </div>
                        </>
                    )}
                    {showOtherComponent && <EmailSendSuccessFully />}
                </Box>
            </Container>

            <Snackbar open={isError} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
                    A megadott e-mail cím nem megfelelő!
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default CardProposal;
