import React from 'react';

const StepTwoContent = () => {
    return (
        <>
            <p style={{marginBottom: '20px'}}>Légyszi állítsd ki a számlát a parának</p>
        </>
    );
};

export default StepTwoContent;
