import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import loginReducer, { loadLoginStateFromLocalStorage, saveLoginStateToLocalStorage } from './store/loginSlice';
import App from './App'; // Replace 'App' with the root component of your application

const persistedLoginState = loadLoginStateFromLocalStorage();

const store = configureStore({
    reducer: {
        login: loginReducer,
    },
    preloadedState: {
        login: persistedLoginState,
    },
});

// Save login state to local storage whenever the state changes
store.subscribe(() => {
    const { login } = store.getState();
    saveLoginStateToLocalStorage(login);
});

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
