// ConfirmationModal.js
import React from 'react';
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import Button from "@mui/material/Button";
import AnnouncementIcon from '@mui/icons-material/Announcement';

const ConfirmationModal = ({ open, onClose, onConfirm }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#fff',
                width: 400,
                padding: '25px',
                borderRadius: '8px',
                boxShadow: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <AnnouncementIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '25%',
                    fontSize: '72px',
                    color: '#088651'
                }} />
                <p className="boxTitle">
                    Biztosan véglegesíti? Ez a művelet nem visszavonható!
                </p>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Button variant="outlined" onClick={onClose}>Mégse</Button>
                    <Button variant="contained" onClick={onConfirm}>Megerősít</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;
