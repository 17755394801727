import { createSlice } from '@reduxjs/toolkit';
import usersData from '../components/Users.json';

const loginSlice = createSlice({
    name: 'login',
    initialState: {
        user: null,
        isLoggedIn: false,
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload; // Store the logged-in user data
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.user = null; // Clear the user data when logging out
        },
    },
});

export const { loginSuccess, logout } = loginSlice.actions;

export const selectIsLoggedIn = (state) => state.login.isLoggedIn;
export const selectUser = (state) => state.login.user;

export const fetchUsersData = () => {
    return new Promise((resolve) => {
        // Simulate fetching data from JSON file (you can use an API call in real-world scenarios)
        resolve(usersData.users);
    });
};

export const loginUser = (email) => async (dispatch) => {
    const users = await fetchUsersData();
    const user = users.find((user) => user.email === email);
    if (user) {
        dispatch(loginSuccess(user));
    }
};

// Save login state to local storage
export const saveLoginStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('loginState', serializedState);
    } catch (error) {
        // Handle errors here
    }
};

// Load login state from local storage
export const loadLoginStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('loginState');
        if (serializedState === null) {
            return undefined; // Return undefined to let Redux use the initial state
        }
        return JSON.parse(serializedState);
    } catch (error) {
        return undefined; // Return undefined to let Redux use the initial state
    }
};

export default loginSlice.reducer;
