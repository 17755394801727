import React, { useState } from 'react';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepOneContent from '../components/Sales/StepOneContent';
import StepTwoContent from '../components/Sales/StepTwoContent';
import StepThreeContent from '../components/Sales/StepThreeContent';
import ConfirmationModal from '../components/Sales/ConfirmationModal';

const steps = ['Adatok rögzítése', 'Számla kiállítása', 'Fizetés',];

const Sales = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            window.location.href = '/'; // Redirect to home if in the 0th step
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleConfirmationOpen = () => {
        setOpenConfirmation(true);
    };

    const handleConfirmationClose = () => {
        setOpenConfirmation(false);
    };

    const handleFinalize = () => {
        handleConfirmationClose();
    };

    return (
        <Container sx={{ display: 'flex', alignItems: 'center', height: '80vh', justifyContent: 'center' }}>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                bgcolor: '#fff',
                width: '460px',
                borderRadius: '8px',
                justifyContent: 'center',
                alignContent: 'center',
                padding: '25px'
            }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <Typography>All steps completed</Typography>
                    ) : (
                        <>
                            {activeStep === 0 && <StepOneContent />}
                            {activeStep === 1 && <StepTwoContent />}
                            {activeStep === 2 && <StepThreeContent />}
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                <Button
                                    variant="outlined"
                                    style={{ marginTop: '25px', width: '45%' }}
                                    onClick={handleBack}>
                                    Vissza
                                </Button>
                                <Button
                                    style={{ marginTop: '25px', width: '45%' }}
                                    variant="contained" color="primary"
                                    onClick={activeStep === steps.length - 1 ? handleConfirmationOpen : handleNext}>
                                    {activeStep === steps.length - 1 ? 'Véglegesítés' : 'Következő'}
                                </Button>
                            </div>
                        </>
                    )}
                </div>

                {/* Confirmation Modal */}
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={handleConfirmationClose}
                    onConfirm={handleFinalize}
                />
            </Box>
        </Container>
    )
}

export default Sales;
