import React from 'react';

const StepThreeContent = () => {
    return (
        <>
            <p style={{marginBottom: '20px', textAlign: 'center'}}>
                Vedd el a pénzt vagy az életét
            </p>
        </>
    );
};

export default StepThreeContent;
