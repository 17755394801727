import React, {useState} from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import {MuiTelInput} from 'mui-tel-input'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Register = () => {
    const [value, setValue] = React.useState('');
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState(false);

    const handleChange = (newValue) => {
        setValue(newValue)
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleNextClick = () => {
        if (!isValidEmail(email)) {
            setIsError(true); // Érvénytelen e-mail esetén isError értékét true-ra állítjuk
            return;
        }

        // További teendők a helyes e-mail esetén
        window.location.href = '/'; // Például visszairányítás a kezdőoldalra
    };

    const isValidEmail = (email) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
    };

    const handleCloseSnackbar = () => {
        setIsError(false); // Értesítés bezárása
    };

    return (
        <>
            <Container sx={{display: 'flex', alignItems: 'center', height: '80vh', justifyContent: 'center'}}>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    bgcolor: '#fff',
                    minHeight: '300px',
                    width: '550px',
                    borderRadius: '8px',
                    justifyContent: 'center',
                    alignContent: 'center',
                    padding: '25px'
                }}>
                    <p className="boxTitle">A regisztrációhoz töltse ki az alábbi mezőket:</p>
                    <div style={{display:"flex", displayDirection:'row', gap:'10px'}}>
                    <TextField
                        style={{width:'50%'}}
                        id="standard-basic"
                        helperText="Vezetéknév"
                        variant="standard"
                    />
                    <TextField
                        style={{width:'50%'}}
                        id="standard-basic"
                        helperText="Keresztnév"
                        variant="standard"
                    />
                    </div>
                    <TextField
                        id="standard-basic"
                        helperText="E-mail cím"
                        variant="standard"
                        value={email} onChange={handleEmailChange}
                    />

                    <MuiTelInput style={{marginTop: '15px', padding: '0px'}} helperText="Telefonszám" value={value}
                                 onChange={handleChange} defaultCountry="HU"/>

                    <div style={{display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between'}}>
                        <Button style={{marginTop: '25px', width: '45%'}} variant="outlined" onClick={() => {
                            window.location.href = '/';
                        }}>Vissza</Button>
                        <Button style={{marginTop: '25px', width: '45%'}} variant="contained"
                                onClick={handleNextClick}>Tovább</Button>
                    </div>
                </Box>
            </Container>

            <Snackbar open={isError} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
                    A megadott e-mail cím nem megfelelő!
                </MuiAlert>
            </Snackbar>
        </>
    )
}

export default Register;
