import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {logout, selectIsLoggedIn, selectUser} from '../store/loginSlice';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3d5a80',
        },
        secondary: {
            main: '#fff',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    transition: 'none', // Disable transitions for the button
                },
            },
        },
    },
});

const Header = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const user = useSelector(selectUser);

    const handleLogout = () => {
        dispatch(logout());
        // Töröljük a felhasználót a localstorage-ból is, ha szükséges
        // localStorage.removeItem('loginState');
    };

    return (
        <ThemeProvider theme={theme}>
            {isLoggedIn ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '10vh',
                        justifyContent: 'space-between',
                        margin: '0px 15px'
                    }}
                >
                    <p className="logged-in-name">
                        Bejelentkezve: <span className="name">{user.lastName} {user.firstName}</span>
                    </p>
                    <Button color="secondary" variant="outlined" onClick={handleLogout}>
                        Kijelentkezés
                    </Button>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '10vh',
                        justifyContent: 'end',
                        margin: '0px 15px'
                    }}
                >
                    <Button color="secondary" variant="outlined" onClick={() => {
                        window.location.href = '/register';
                    }}>
                        Regisztráció
                    </Button>
                </Box>
            )}
        </ThemeProvider>
    );
};
export default Header;
