import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {loginUser, logout, selectIsLoggedIn, selectUser, saveLoginStateToLocalStorage} from '../store/loginSlice';
import {useNavigate} from 'react-router-dom';
import LoginForm from '../components/Login/LoginForm';

const Login = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const user = useSelector(selectUser);
    const navigate = useNavigate(); // Initialize useNavigate hook

    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState(false);
    const [isUserNotFound, setIsUserNotFound] = useState(false); // State to handle user not found error

    const handleLogin = () => {
        if (isValidEmail(email)) {
            setIsError(false);
            // Simulate user not found error if email is not "test@test.com"
            if (email === 'test@test.com') {
                setIsUserNotFound(true);
            } else {
                setIsUserNotFound(false);
                dispatch(loginUser(email));
            }
        } else {
            setIsError(true);
        }
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    useEffect(() => {
        // Save login state to local storage whenever the state changes
        saveLoginStateToLocalStorage({isLoggedIn, user});

        // If isLoggedIn is true, redirect to "/"
        if (isLoggedIn) {
            navigate('/'); // Use navigate to redirect to "/"
        }
    }, [isLoggedIn, user, navigate]); // Add navigate to the dependency array

    const handleCloseSnackbar = () => {
        setIsError(false); // Értesítés bezárása
        setIsUserNotFound(false); // Értesítés bezárása
    };

    const isValidEmail = (email) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
    };

    return (
        <>
            {isLoggedIn ? (
                <Container sx={{display: 'flex', alignItems: 'center', height: '80vh', justifyContent: 'center'}}>
                    {/* Logged-in section */}
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        bgcolor: '#fff',
                        minHeight: '300px',
                        width: '460px',
                        borderRadius: '8px',
                        justifyContent: 'center',
                        alignContent: 'center'
                    }}>
                        {/* Hello message and Logout button */}
                        <p>Hello, {user.firstName}!</p>
                        <Button variant="contained" onClick={handleLogout}>
                            Logout
                        </Button>
                    </Box>
                </Container>
            ) : (
                <Container sx={{display: 'flex', alignItems: 'center', height: '80vh', justifyContent: 'center'}}>
                    {/* Login form section */}
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        bgcolor: '#fff',
                        minHeight: '300px',
                        width: '460px',
                        borderRadius: '8px',
                        justifyContent: 'center',
                        alignContent: 'center',
                        padding: '35px'
                    }}>
                        <LoginForm email={email} setEmail={setEmail} handleLogin={handleLogin}/>
                    </Box>
                </Container>
            )}

            <Snackbar open={isError} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
                    A megadott e-mail cím nem megfelelő!
                </MuiAlert>
            </Snackbar>


            <Snackbar open={isUserNotFound} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
                    Felhasználó nem található!
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default Login;
