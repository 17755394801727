import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const LoginForm = ({ email, setEmail, handleLogin }) => {
    return (
        <>
            <p className="boxTitle">Jelentkezzen be az alkalmazás használatához:</p>
            <TextField
                id="standard-basic"
                helperText="E-mail cím"
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                }}
            >
                <Button style={{ width: '100%' }} variant="contained" onClick={handleLogin}>
                    Tovább
                </Button>
            </div>
        </>
    );
};

export default LoginForm;
